/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getAllRoutes } from "@hyperlocal/banking-utility";

type AllRoutes = ReturnType<typeof getAllRoutes>;

export const buildLayout = (routes: AllRoutes) => {
  const {
    statementsRoutes,
    salesRoutes,
    pixRoutes,
    transfersRoutes,
    cashOutRoutes,
    receiptRoutes,
    receivedRoutes,
    tokenRoutes,
    chargeRoutes,
    cardRoutes,
  } = routes;

  return `  
  <single-spa-router>
    <main id="hyperlocal-main">
      <nav>
        <application name="@hyperlocal/banking-nav-bar"></application>
      </nav>

      <route default>
        <application name="@hyperlocal/banking-dashboard"></application>
      </route>

      <route path=${statementsRoutes.statements}>
        <application name="@hyperlocal/banking-statements"></application>
      </route>

      <route path=${tokenRoutes.token}>
        <application name="@hyperlocal/banking-token-validation"></application>
      </route>

      <route path=${receiptRoutes.receipts}>
        <application name="@hyperlocal/banking-receipt"></application>
      </route>

      <route path=${cashOutRoutes.cashOut}>
        <application name="@hyperlocal/banking-cash-out"></application>
      </route>

      <route path=${transfersRoutes.transfers}>
        <application name="@hyperlocal/banking-transfers"></application>
      </route>

      <route path=${pixRoutes.pix}>
        <application name="@hyperlocal/banking-pix"></application>
      </route>

      <route path=${chargeRoutes.charge}>
        <application name="@hyperlocal/banking-charge"></application>
      </route>

      <route path=${chargeRoutes.futureInstallments}>
        <application name="@hyperlocal/banking-charge"></application>
      </route>

      <route path=${salesRoutes.salesReport}>
        <application name="@hyperlocal/acquiring-sales"></application>
      </route>

      <route path=${receivedRoutes.received}>
        <application name="@hyperlocal/acquiring-received"></application>
      </route>

      <route path=${receivedRoutes.futureReceipts}>
        <application name="@hyperlocal/acquiring-received"></application>
      </route>

      <route path=${cardRoutes.cards}>
        <application name="@hyperlocal/banking-cards" class="hyperlocal-cards"></application>
      </route>
    </main>
  </single-spa-router>
  `;
};
