const rootRoute = "/";

const routes = {
  DashboardRoutes: {
    cashOutBoleto: "/cashout/boleto",
    cashOutBoletoCopiaCola: "/cashout/boleto/copia-cola",
    cashOutPix: "/cashout/pix",
    cashOutPixCopiaCola: "/cashout/pix/copia-cola",
    dashboard: rootRoute,
    dashboardPay: "/pay",
    dashboardPayAnticipation: "/pay/antecipacao",
    dashboardPayAnticipationReceipt: "/received/future-receipts",
    pixDashboard: "/pix",
    receipts: "/comprovantes",
    statements: "/extrato",
    transfers: "/transferencia",
    transfersDeposit: "transferencia/dados",
    salesReport: "/sales-report",
    received: "/received",
    futureReceipts: "/received/future-receipts",
    futureReceiptsCalendar: "/received/future-receipts/calendar",
    charge: "/cobranca",
    cards: "/cartoes",
    settings: "/configuracoes",
    settingsNewAccess: "/configuracoes/novo-acesso",
    settingsUserHistory: "/configuracoes/user/:id",
    sessionLogin: "/session/login",
    sessionResetPassword: "/session/reset-password",
    pendingTransactions: "/lancamentos-pendentes",
    pendingTransactionsDetails: "/lancamentos-pendentes/detalhes",
    contracts: "/contratos",
  },
  BalanceRoutes: {
    dashboard: rootRoute,
  },
  SalesRoutes: {
    salesReport: "/sales-report",
    salesReportDetails: "/sales-report/details",
    salesReportFilters: "/sales-report/filters",
    dashboard: rootRoute,
  },
  ReceivedRoutes: {
    received: "/received",
    receivedDetails: "/received/details",
    receivedFillters: "/received/filters",
    futureReceipts: "/received/future-receipts",
    futureReceiptsDetails: "/received/future-receipts/details",
    futureReceiptsFilters: "/received/future-receipts/filters",
    futureReceiptsCalendar: "/received/future-receipts/calendar",
    futureReceiptsCalendarDetails: "/received/future-receipts/calendar/details",

    futureReceiptsAnticipation: "/received/future-receipts/anticipation",
    futureReceiptsAnticipationDetails:
      "/received/future-receipts/anticipation/details",
    futureReceiptsAnticipationFilters:
      "/received/future-receipts/anticipation/filters",
    futureReceiptsAnticipationReceipt:
      "/received/future-receipts/anticipation/receipt",
  },
  TransfersRoutes: {
    dashboard: rootRoute,
    transfers: "/transferencia",
    transfersData: "/transferencia/dados",
    transfersConfirmTransfer: "/transferencia/confirmar",
    transfersEditContact: "/transferencia/editar-contato",
    transfersConfirmRecipient: "/transferencia/confirmar-destinatário",
    transferToken: "/transferencia/confirmar/token",
    transferReceipt: "/transferencia/confirmar/comprovante",

    transfersPayment: "/transferencia/pagamento",
    transfersPaymentTED: "/transferencia/pagamento?operacao=ted",
    transfersPaymentPix: "/transferencia/pagamento?operacao=pix",
    transfersPaymentP2P: "/transferencia/pagamento?operacao=p2p",
    transfersDeposit: "/transferencia/dados",
    transfersTED: "/transferencia/ted",

    receiptPayment: "/comprovantes/pagamento",

    pixNewKey: "/pix/minhas-chaves/nova",
  },
  PixRoutes: {
    pix: "/pix",
    pixMyKeys: "/pix/minhas-chaves",
    pixMyKeysNew: "/pix/minhas-chaves/nova",
    pixCharge: "/pix/cobranca",
    pixContestation: "/pix/contestacao",
    pixChargeShare: "/pix/cobranca/compartilhar",
    pixLimits: "/pix/configuracoes",
    token: "/token",

    dashboard: rootRoute,
    statements: "/extrato",
    transfer: "/transferencia",
    cashOutQR: "/cashout/pix",
    copyPaste: "/cashout/pix/copia-cola",
  },
  StatementsRoutes: {
    dashboard: rootRoute,
    dashboardPay: "/pay",
    transfers: "/transferencia",
    statements: "/extrato",
    commitments: "/extrato/compromissos",
    statementsDetails: "/extrato/detalhes",
    receipts: "/comprovantes",
  },
  ReceiptRoutes: {
    receiptPayment: "/comprovantes/pagamento",
    receiptDetails: "/comprovantes/detalhes",
    receipts: "/comprovantes",
    pix: "/pix",
    dashboard: rootRoute,
    receiptDetail: "/comprovantes/:receiptId",
  },
  CashOutRoutes: {
    cashOut: `/cashout`,
    cashOutPix: "/cashout/pix",
    cashOutPixCopiaCola: "/cashout/pix/copia-cola",
    cashOutPixPayment: "/cashout/pix/pagamento",
    cashOutBoleto: "/cashout/boleto",
    cashOutBoletoCopiaCola: "/cashout/boleto/copia-cola",
    cashOutBoletoPayment: "/cashout/boleto/pagamento",
    dashboard: rootRoute,
    token: "/token",
    receiptPayment: "/comprovantes/pagamento",
    pixDashboard: "/pix",
  },
  TokenRoutes: {
    token: "/token",
  },
  ChargeRoutes: {
    dashboard: rootRoute,
    charge: "/cobranca",
    futureInstallments: "/cobranca/parcelas-futuras/:id",
    futureInstallmentDetail: "/cobranca/parcelas-futuras/:id/detalhes",
    token: "/token",
    newCharge: "/cobranca/nova",
    newChargeReceipt: "/cobranca/nova/comprovante",
  },
  CardsRoutes: {
    dashboard: rootRoute,
    cardNewPassword: "/cartoes/nova-senha",
    confirmTOTP: "/cartoes/confirmar-TOTP",
    cards: "/cartoes",
    newCardAddress: "/cartoes/novo/endereco",
    newCardPin: "/cartoes/novo/senha",
    newCardResume: "/cartoes/novo/resumo",
    newCardholder: "/cartoes/novo/titular",
    newCardConfirmTOTP: "/cartoes/novo/confirmar-TOTP",
    newCardNickname: "/cartoes/novo/apelido",
    confirmCardDelivery: "/cartoes/confirmar-recebimento",
  },
};

const initRoutes = async () => {
  const utility = await System.import("@hyperlocal/banking-utility");

  Object.keys(routes).forEach((route) => {
    utility[`set${route}Data`](routes[route]);
  });
};

export default initRoutes;
