/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getAllRoutes } from "@hyperlocal/banking-utility";
import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";
import initRoutes from "./hyperlocal-banking-routes";
import { buildLayout } from "./layout";

const allRoutes = getAllRoutes();

const routes = constructRoutes(buildLayout(allRoutes));
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

initRoutes();

layoutEngine.activate();
start();
